$opaque-white: rgba(255, 255, 255, 1);
$table-stripe-gray: rgba(230, 230, 230, 1);
$primary-blue: rgba(0, 123, 255, 1);

$cell-checkbox-hover: $table-stripe-gray;
$cell-checkbox-checked: $primary-blue;
$cell-checkbox-active: darken($cell-checkbox-checked, 10%);


button.loading-button:disabled {
  background-color: lighten($primary-blue, 30%);
}

div.div-toggle-button {
  border-radius: 5px;
  cursor: pointer;
}

div.div-toggle-button:hover {
  background-color: $table-stripe-gray;
  transition: 0.15s;
}

div.div-toggle-button:active {
  background-color: darken($table-stripe-gray, 10%);
  transition: 0.15s;
}


button.nav-link {
  border: 0;
  background-color: rgba(0, 0, 0, 0);
}

.btn.right-align {
  text-align: right;
  right: 0;
}

.checkbox-cell {
  padding: 0 !important;
}

.checkbox-cell .checkbox-label {
  display: block;
  padding: 0.5rem 1rem;
}

.checkbox-cell .checkbox {
  display: none;
}

.cell-checkbox {
  :checked > & {
    background-color: $cell-checkbox-checked;
    transition: 0.25s;
    color: #FFFFFF;
  }
}


div.form-row.no-margins {
  margin-left: 0;
  margin-right: 0;
}

div.form-row.justify-right {
  justify-content: flex-end;
  padding-bottom: 0.5rem;
}


.checkbox-cell label {
  margin-bottom: 0;
}

.checkbox-cell:hover {
  background-color: $cell-checkbox-hover;
  transition: 0.15s;
}

.checkbox-cell:active {
  background-color: $cell-checkbox-active;
  transition: 0.05s;
  color: #FFFFFF;
}

.checkbox[type=checkbox]:checked + .checkbox-label {
  background-color: $cell-checkbox-checked;
  transition: 0.25s;
  color: #FFFFFF;
}

.multi-email-input {
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.5;
  padding: 0.4em 0.5em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  font-size: 13px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}
.multi-email-input > span[data-placeholder] {
  display: none;
  left: 0.5em;
  top: 0.4em;
  padding: 0.4em;
  line-height: 1rem;
}

.multi-email-input.focused {
  border-color: #85b7d9;
  background: #fff;
}

.multi-email-input.empty > span[data-placeholder] {
  display: inline;
  color: #ccc;
}

.multi-email-input.focused > span[data-placeholder] {
  display: none;
}

.multi-email-input > input {
  width: auto !important;
  line-height: 1.5;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  vertical-align: baseline !important;
  margin: 0.4em 0.1em !important;
}

.multi-email-input [data-tag] {
  line-height: 1;
  vertical-align: baseline;
  margin: 0.14285714em;
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.6);
  background-image: none;
  padding: 0.5833em 0.833em;
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.multi-email-input [data-tag].danger {
  background-color: #ef5350;
  color: #000000;
}

.multi-email-input [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.multi-email-input [data-tag]:first-child {
  margin-left: 0;
}
.multi-email-input [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}
