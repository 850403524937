

.sighting-age-legend-scale {
    /* 
    Base color matches the base color specified for sighting icons, 
    which is defined in:
        src/map/Layers.ts in function 'sightingsParamFactory'
    */
    background: #00b300;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    background: linear-gradient(to right, #00b300 0%, #000000 100%);  
    border-radius: 0.25rem;  
    height: 1.75rem;
    min-height: 1.75rem;
}
