body {
    margin: 0;
    color: blue;
}

.alert.no-pos,
.alert-dismissible.no-pos {
  position: unset;
}

.alert .close,
.alert-dismissible .close {
  padding: inherit;
}

.very-small-text {
  font-size: 80%;
  line-height: 125%;
}

.mt-config-item {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  height: auto;
  width: auto;
  justify-content: center;
  align-items: center;
}


.card ul {
  margin-bottom: 0;
}

.btn.loading-button {
  min-height: 2rem;
}

.btn.loading-button.is-loading {
  opacity: 0.7;
}

.btn.loading-button.is-loading * {
  opacity: 1;
}

.spinner-placeholder {
  min-width: 2.125rem;
  min-height: 2.125rem;
}


.multiple-select-item:hover {
  background-color: #333333;
  transition: 0.15s;
}

span.capitalize {
  text-transform: capitalize;
}

b.capitalize {
  text-transform: capitalize;
}

.loading-spinner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.flex-nowrap span {
  white-space: nowrap;
}


#home_logo {
  width: 15rem;
}
