$gray: rgba(230, 230, 230, 1);
$hover-gray: darken($gray, 10%);
$active-gray: darken($gray, 20%);

body {
	margin: 0;
	padding: 0;
}


button.map-nav-toggle {
	border-radius: 4px;
	border-width: 1px;
	border-color: #000000;
	background-color: $gray;
	opacity: 0.95;
	display: flex;
	flex-flow: row-reverse nowrap;
	justify-content: center;
	align-items: center;
	align-self: center;
	width: auto;
}

button.map-nav-toggle:hover {
	background-color: $hover-gray !important;
	transition: 0.2s !important;
	opacity: 1;
}

button.map-nav-toggle:active {
	background-color: $active-gray !important;
	transition: 0.1s !important;
}

.full-page-map-container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	cursor: pointer;
	z-index: 1;
}

div.map-menu-legend-icon-images {
	width: auto;
	display: inline-flex;
	justify-content: start;
	flex-flow: row nowrap;
	align-items: center;
	align-self: center;
	margin-right: 0.25rem;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

div.map-menu-legend-icon {
	width: auto;
	display: flex;
	justify-content: start;
	flex-flow: row nowrap;
	align-items: center;
	align-self: center;
}

div.map-menu-legend-icon span {
	white-space: nowrap;
}

div.map-menu-legend-item {
	width: 100%;
	height: auto;
	padding: 0;
	margin: 0 0 0.25rem 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	align-self: center;
}

.map-menu-legend-item-toggle {
	border-radius: 0.25rem;
}

.map-menu-legend-item-grey-out {
	background-color: $active-gray;
	opacity: 0.5;
	transition: 0.15s;
}

.map-menu-legend-item-toggle:hover {
	background-color: $hover-gray;
	transition: 0.15s;
}
.map-menu-legend-item-toggle-active {
	background-color: $active-gray;
	transition: 0.15s;
}
div.map-menu {
	opacity: 0.8;
	// allow for vertical scrolling if the dropdown overflows
	overflow-y: auto;
	overflow-x: hidden;
	// max size is the entire viewport height, minus a constant amount for the 
	// dropdown toggle button and map attribution at the bottom. 5rem picked arbitrarily, 
	// seems to work well 
	max-height: calc(100vh - 5rem);
}

div.map-menu:hover {
	opacity: 1;
	transition: 0.1s;
}

div.map-menu > * {
	line-height: 1rem;
}

div.map-menu-legend-toggle {
	border-radius: 0.1rem;
}

div.map-menu-legend-toggle:hover {
	background-color: $hover-gray;
	transition: 0.15s;
}

div.map-menu-legend-toggle:active {
	background-color: $active-gray;
	transition: 0.15s;
}

div.image-button {
	display: inline-block;
	border-radius: 0.1rem;
}

div.image-button:hover {
	background-color: #A6A6A6;
	transition: 0.15s;
}

div.image-button:active {
	background-color: darken(#A6A6A6, 5%);
	transition: 0.05s;
}

.mapboxgl-ctrl button.btn-success {
	border-width: 1px;
	border-color: #000000;
}

.mapboxgl-ctrl button.btn-success:not(:disabled) {
	opacity: 0.95;
}

.mapboxgl-ctrl button.btn-success:not(:disabled):hover {
  background-color: #146c43; // bootstrap success onhover
	opacity: 1;
}

.mapboxgl-ctrl button.btn-outline-secondary:not(:disabled):hover {
  color: #6c757d; // bootstrap success onhover
  background-color: lighten(#6c757d, 35%);
}

.mapboxgl-ctrl button.btn-outline-secondary:focus,
.mapboxgl-ctrl button.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.mapboxgl-ctrl button.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
	padding: 0rem 0.2rem 0.2rem 0.2rem;
	height: 1.5rem;
}

.card-img.auto-width {
	width: auto;
}

.card-footer.reduced-padding {
	padding: 0.01rem 0.1rem;
	margin: -0.1rem 0rem;
}

.card-footer.center-text {
	vertical-align: middle;
	text-align: center;
}


.list-group-item.no-padding {
	padding: 0;
}

.list-group-item.reduced-padding {
	padding: 0.35rem;
}

.refresh-spinner {
	padding: 0;
	margin: 0;
	display: inline;
	text-align: right;
	width: auto;
	z-index: 10000;
}


.dropdown-item-text.no-vert-padding,
.dropdown-item.no-vert-padding {
	padding: 0rem 1rem 0rem 1rem;
}

.dropdown-item-text.no-horiz-padding,
.dropdown-item.no-horiz-padding {
	padding: 1rem 0rem 1rem 0rem;
}

.dropdown-item-text.no-padding,
.dropdown-item.no-padding {
	padding: 0rem;
}


.icon-canvas {
	padding-right: 4.5px;
	padding-left: 2px;
}

.dropdown-divider.divider-major {
	margin: 0.4rem 0.75rem;
	border-top: 2px solid;
	border-color: inherit;
}


.dropdown-divider.divider-minor {
	margin: 0.4rem -0.75rem;
}

.dropdown-header.header-major {
	padding: 0rem 1rem 0rem 1rem;
	margin: -0.1rem 0rem;
	font-size: medium;
}

.dropdown-header.header-minor {
	padding: 0.2rem 1rem;
	font-size: 95%;
}

.dropdown-header.no-padding {
	padding: 0;
}

.spinner-border.high-z {
	z-index: 100;
}

.inline-header {
	display: inline-block;
	white-space: nowrap;
	vertical-align: middle;
}

.custom-switch .custom-control-label.fix-left-offset::before {
	left: -1.75rem;
}

.custom-switch.fix-left-offset {

}

.custom-switch.fix-left-offset label::after {
	left: calc(-1.75rem + 2px);
}

.custom-switch.fix-left-offset label::before {
	left: -1.75rem;
}

.custom-switch .custom-control-label.fix-left-offset-small::after {
	left: calc(-1.875rem + 2px);
}

.custom-switch .custom-control-label.fix-left-offset-center::before {
	left: -0.875rem;
}

.custom-switch .custom-control-label.fix-left-offset-center::after {
	left: calc(-0.875rem + 2px);
}



.slim-margins {
	margin: -0.05rem 0rem;
	padding: 0.05rem 0rem;
}

div.right-align {
	display: inline;
	text-align: right;
}

.no-wrap {
	white-space: nowrap;
	width: max-content;
}

.no-wrap :is(div, span) {
	white-space: nowrap;
	width: max-content;
}

.smaller-text {
	font-size: 87%;
}

button.map-menu-toggle {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
  align-self: center;
}

.dropdown-toggle::after {
  margin-left: auto !important;
  margin-right: auto !important;
}

button.client-info-toggle {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
  align-self: center;
}

.dropdown.left-pad {
	padding-left: 0.75rem;
}

.dropdown.map-menu {
	position: absolute;
	display: block;
	top: 0px;
	right: 0px;
	z-index: 2;
}

.dropdown-menu.show {
	width: auto;
}


.dropdown.center-middle {
	vertical-align: middle;
}

.dropdown.auto-margin {
	margin-top: auto;
	margin-bottom: auto;
}


.mapboxgl-popup-content
{
	padding: 0;
	margin: 0;
}

.mapboxgl-popup-content-wrapper
{
	padding: 0;
	margin: 0;
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
}

.no-padding .btn {
	padding: 0.15rem;
}

.mapbox-popup-container
{
	min-width: 20rem;
	max-width: 20rem;
	max-height: 25rem;
	overflow-y: auto;
	padding: 0;
	margin: 0;
}

div.center {
	margin-left: auto;
	margin-right: auto;
	display: inline-block;
}

span.indent {
	text-indent: 1cm;
}

.centered_div_container {
	text-align: center;
	bottom: 0.1cm;
	pointer-events: none;
	position: fixed;
	width: 100%;
	z-index: 100;
}

.centered_div {
	bottom: 0.1cm;
	pointer-events: all;
	margin-left: auto;
	margin-right: auto;
	width: 4cm;
	z-index: 100;
}

.layer_container_div {
	align-items: center;
	vertical-align: middle;
	padding: 5px;
	border: solid;
	border-width: thin;
	border-color: black;
}

#logo {
	width: 20vmin;
	height: auto;
	z-index: 100;
	opacity: 0.66;
}

#logo_link {
	z-index: 100;
	width: auto;
	height: auto;
}



.map_overlay {
	position: absolute;
	display: block;
	bottom: 0;
	right: 0;
	background: rgba(255,255,255,0.5);
	margin-right: 10px;
	margin-bottom: 80px;
	font-family: Arial, sans-serif;
	font-size: 14px;
	overflow: auto;
	border-radius: 4px;
	z-index: 1000;
	padding: 10px;
}
