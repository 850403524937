.station-status-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, auto));
  grid-auto-flow: dense;
  // grid-auto-columns: min-content auto;
  grid-column-start: auto;
  // grid-auto-rows: min-content auto;
  align-content: stretch;
  gap: 1rem;
  width: 100%;
}

.station-card-grid {
  width: auto;
  flex-shrink: 1;
}

.station-card-grid > .card-body {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  //grid-auto-columns: min-content;
  grid-auto-rows: auto;
  // grid-auto-rows: auto;
  // grid-template-columns: repeat(auto-fit, min-content);
  // grid-template-columns: auto;
  grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
  width: 100%;
  gap: 1rem;
  background-color: #123456 !important;
}

.station-card {
  width: auto;
}


.station-card-grid > .card-body > .card {
  //width: min-content;
  height: min-content;
  //align-items: stretch;
}


.station-card-body {
  display: flex;
  flex-direction: column;
  width: auto;
  height: min-content;
}

.station-status-fields {
  display: grid;
  grid-auto-rows: auto;
  width: min-content;
  grid-column-gap: minmax(0.5rem, auto-fit);
  grid-row-gap: 0.1rem;
  grid-template-columns: repeat(2, auto-fit);
}


.station-status-field-name {
  width: auto;
  justify-content: flex-start;
  grid-column: 1;
}

.station-status-field-name > div {
  white-space: nowrap;
  text-align: left;
  width: 100%;
}

.station-status-field-value {
  width: auto;
  justify-content: flex-end;
  grid-column: 2;
}


.station-status-field-value > div {
  white-space: nowrap;
  width: 100%;
  text-align: right;
}


.station-status-field-row {
  grid-column: 1 / 2;
}

.left-align {
  text-align: left;
}

.left-align:first-of-type {
  padding-left: 0;
}

.hang-right {
  right: 0;
  text-align: right;
}

.width-content {
  max-width: max-content !important;
}

div.one-third {
  width: 33.3%;
  text-align: left;
}

div.two-thirds {
  width: 66.6%;
  text-align: right;
}

hr.no-margins {
  margin: 0.05rem;
}

hr.white {
  border-color: #FFFFFF;
}

.right-align {
  text-align: right;
}

.map-client-info {
  min-width: min-content !important;
  width: max-content !important;
}

//.right-align:last-of-type {
//  padding-right: 0;
//}

.center-horiz {
  margin-left: auto;
  margin-right: auto;
}

.center-horiz.med-margins {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.middle {
  vertical-align: middle !important;
}

.no-top-margins {
  margin-top: 0 !important;
}


.no-right-margins {
  margin-right: 0 !important;
}

// --------------- Container ------------- //


.container-fluid.small-top-pad,
.container.small-top-pad {
  padding-top: 1rem;
}

.container.rounded-border,
.container-fluid.rounded-border {
  border: 1.5px solid #dee2e6;
  border-radius: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.rounded-border.no-top-border {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
}

.container.no-max-width {
	max-width: none!important;
}


.container-fluid.pad-sides {
  padding-left: 5rem;
  padding-right: 5rem;
}


.container.center-middle,
.container-fluid.center-middle {
	vertical-align: middle;
	text-align: center;
}

.container.medium-margin,
.container-fluid.medium-margin {
	margin: 5rem 0rem;
}



.container-fluid.small-vert-padding,
.container.small-vert-padding {
	padding: 0rem 0.2rem;
}


.container-fluid.no-margin,
.container.no-margin {
	margin: 0;
}

.container.no-side-padding,
.container-fluid.no-side-padding {
  padding-left: 0;
  padding-right: 0;
}

// ----- No Padding ----- //
.container-fluid.no-padding,
.container.no-padding {
	padding: 0;
}

.container-fluid.no-padding .row,
.container.no-padding .row {
	padding: 0;
}
.container-fluid.no-padding .col,
.container.no-padding .col {
	padding: 0;
}

// ------ Right Align ------ //
.container-fluid.right-align,
.container.right-align {
	text-align: right;
	position: absolute;
	height:auto;
	right: 0;
	top: 0;
}


// ----------------- Row ----------------- //


.row.centered-row .col-md-auto,
.row.centered-row .col {
	vertical-align: middle;
}

.row.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row.no-gutters {
  --bs-gutter-x: 0;
}

.row.no-margins {
	margin: 0;
}

.row.flex-row {
	display: flex;
	flex-basis: auto;
	flex-wrap: nowrap;
}

.row.flex-row.right-align {
	flex-basis: 50%;
}

.row.only-vert-padding .col {
	padding: 0.3rem 0rem;
}

.row.inline-row .col {
	display: flex;
}

.row.medium-padding {
  padding: 0.3rem;
}


// ----------------- Col ----------------- //

.col.hang-right {
	right: 0;
}

.col-md-auto.reduced-padding,
.col.reduced-padding {
	padding: 0.75rem;
}

.col-md-auto.reduced-padding,
.col.reduced-padding {
	padding: 0.75rem;
}

.col.reduced-vert-margins {
	margin: -0.25rem 0rem -0.25rem 0rem;
	vertical-align: middle;
}

.col.reduced-vert-margins b {
	vertical-align: middle;
}


.col-md-auto.left-align,
.col.left-align {
	flex-shrink: 1;
	padding-left: 0;
	padding-right: 0.5rem;
	white-space: nowrap;
	text-align: left;
}

.col-md-auto.left-align.center-text,
.col.left-align.center-text {
	white-space: nowrap;
	text-align: center;
}


.col.right-align,
.col-md-auto.right-align {
	padding-left: 0;
	flex-grow: 1;
	text-align: right;
}


.col.inline-col {
	display: flex;
	flex-wrap: nowrap;
}


.col-md-auto.no-padding,
.col.no-padding {
	padding: 0;
}

.col-md-auto.vertical-center,
.col.vertical-center {
	vertical-align: middle;
	margin-top: auto;
	margin-bottom: auto;
}

.col.flex-static-size {
	flex-grow: 0;
	flex-shrink: 0;
}

.col.left-align.pad-left {
  padding-left: 0.75rem;
}

.col.time-label {
	min-width: 7.5rem;
}

.col.flex-expand {
	padding-left: 0;
	padding-right: 0;
	flex-grow: 1;
	flex-shrink: 1;
}


.col-md-auto.medium-margins,
.col.medium-margins {
  margin: 1rem;
}

.thick-borders {
  border-width: 2px !important;
}

.grid-overlap {
  display: grid !important;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0px;
  justify-content: center;
  align-items: center;
  grid-template-areas: "center";
}

.grid-overlap-item {
  grid-area: center;
}
