$opaque-white: rgba(255, 255, 255, 1);
$table-stripe: rgba(230, 230, 230, 1);
$table-stripe-darker: darken($table-stripe, 20%);
$table-stripe-darkest: darken($table-stripe, 40%);

.centered-row th,
.centered-row td {
	text-align: center;
	font-size: 102%;
}

.table td.no-padding,
.table th.no-padding {
	padding: 0;
}


.table.sticky {
  overflow: scroll;
  overscroll-behavior: contain;
  border-color: $table-stripe-darker;
}

.table.sticky td,
.table.sticky tr,
.table.sticky th,
.table.sticky tbody,
.table.sticky thead,
.table.sticky tfoot {
  border-color: inherit;
}

.table.sticky td {
	border-top-width: 1px;
	border-bottom-width: 1px;
}

.sticky-row.pad {
  padding: 1rem;
}

.table-sm td.no-padding {
	padding: 0;
	margin: 0;
}

.table.no-margins {
	margin: 0;
	border-radius: 0;
	border: 0px;
}

.table td {
	vertical-align: middle !important;
}

td.sticky-cell.cell-button,
td.cell-button {
  margin-left: auto;
	margin-right: auto;
}

td.cell-button:hover {
	background-color: $table-stripe-darker;
	transition: 0.15s;
}

td.cell-button:active {
	background-color: $table-stripe-darkest;
	transition: 0.05s;
}

.table-striped.sticky tbody tr:nth-of-type(odd) {
  background-color: $table-stripe;
}

.table-striped.sticky tbody tr:nth-of-type(even) {
  background-color: $opaque-white;
}

.table-striped.sticky thead {
  background-color: $opaque-white;
}

.table.sticky td,
.table.sticky th {
  z-index: 3;
}

.table.sticky tr {
  opacity: 1;
}

tr.sticky-row {
  background-color: inherit;
  position: -webkit-sticky;
  position: sticky;
  z-index: 4;
  opacity: 1;
}


td.sticky-cell,
th.sticky-cell {
  border-color: inherit;
  background-color: inherit;
  padding: inherit !important;
  margin: 0.3rem;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
}

th.sticky-cell.right,
td.sticky-cell.left {
  z-index: 6;
}

th.invisible-cell {
  opacity: 0;
  border-color: rgba(0,0,0,0);
}

.no-left-border {
  border-left: 0 !important;
}

.no-right-border {
  border-right: 0 !important;
}

.no-top-border {
  border-top: 0 !important;
}
.no-bottom-border {
  border-bottom: 0 !important;
}

.no-borders {
  border: 0 !important;
}

th.sticky-cell div,
td.sticky-cell div {
  background-color: inherit;
  white-space: nowrap;
  padding: 0.3rem;
  display: block;
  opacity: 1;
}


table.table {
  margin-bottom: 0;
}

div.table-responsive {
  margin-bottom: 0.5rem;
}
